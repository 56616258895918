import {useState} from 'react'
import mobileThxBackground from '../../../content/media/kot_kontakt-thankyou-m.jpg'
import desktopThxBackground from '../../../content/media/kot_kontakt-thankyou-d.jpg'
import Appshell from '../../components/Appshell'
import Background from '../../uikit/Background'
import mobileBackground from '../../assets/mobile-background.jpg'
import desktopBackground from '../../assets/desktop-background.jpg'
import Text from '../../uikit/Text'
import Flex from '../../uikit/Flex'
import Button from '../../uikit/Button'
import useForm from '../../hooks/useForm'
import TextInput from '../../uikit/form/TextInput'
import Success from '../../components/screens/Success'
import encode from '../../utils/encode'


const text = {
  title: 'zrušenie predplatného',
  description: `Ak si prajete z ľubovoľných dôvodov svoje predplatné zrušiť, jednoducho len zadajte váš e-mail,
  ktorý ste použili naposledy. Ak o zrušenie požiadate práve vtedy, keď budú vaše knihy už vyexpedované, budem
  vás kontaktovať a dohodneme ďalší postup. `,
}

const Cancelation = () => {
  const {values, handleChange} = useForm()
  const [isSubmitted, setSubmitted] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()

    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': 'cancelation',
        ...values,
      }),
    })
      .then(() => {
        setSubmitted(true)
      })
      .catch((error) => console.error(error))
  }

  if (isSubmitted) {
    return (
      <Appshell>
        <Background desktopBackground={desktopThxBackground} mobileBackground={mobileThxBackground}>
          <Success />
        </Background>
      </Appshell>
    )
  }

  return (
    <Appshell>
      <Background desktopBackground={desktopBackground} mobileBackground={mobileBackground}>
        <Flex column>
          <Text component="h1" textStyle="h1Alternate">
            {text.title}
          </Text>
          <p>
            {text.description}
          </p>
          <form
              name="cancelation"
              method="post"
              action="/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
          >
            <Flex column>
              <input type="hidden" name="form-name" value="cancelation" />
              <TextInput
                  type="email"
                  name="email"
                  placeholder="Váš email"
                  value={values?.email}
                  onChange={handleChange}
                  required
              />
              <Flex column crossAxis="center">
                <p>
                  To je všetko. Príďte zas, keď budete mať chuť!
                </p>
                <Button type="submit">
                  Odoslať
                </Button>
              </Flex>
            </Flex>
          </form>
        </Flex>
      </Background>
    </Appshell>
  )
}

export default Cancelation
