import {Link} from 'gatsby'
import Button from '../../uikit/Button'
import Flex from '../../uikit/Flex'
import Text from '../../uikit/Text'
import Envelope from '../../assets/icons/envelope.svg'


const Success = () => {
  return (
    <Flex column crossAxis="center">
      <Text component="h1" textStyle="h1" align="center">
        Vaša požiadavka bola úspešne odoslaná.
      </Text>
      <p>
        V priebehu nasledujúcich dní vám príde potvrdzujúci mail.
      </p>
      <p>
        Ak máte akékoľvek otázky, neváhajte ma osloviť tu:
      </p>
      <Button component={Link} isLink to="/contact">
        <span className="sr-only">Kontakt</span>
        <Envelope />
      </Button>
      <Button component={Link} isLink to="/">
        späť na hlavnú stránku
      </Button>
    </Flex>
  )
}

export default Success
